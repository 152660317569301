import React from "react"
import "./layout.css"
import Header from "./Header";
import Helmet from 'react-helmet'
import Footer from "./Footer";
import CookieConsent from 'react-cookie-consent';


class Layout extends React.Component {
    render() {
        return (
            <>
                <CookieConsent
                    location="bottom"
                    buttonText="Accept"
                    declineButtonText="Decline"
                    enableDeclineButton
                    cookieName="gatsby-gdpr-google-analytics">
                    Wir nutzen Cookies auf unserer Website. Einige sind essenziell für den Betrieb der Seite, während andere uns helfen, diese Website und die Nutzererfahrung zu verbessern.
                    Sie können selbst entscheiden, ob Sie die Cookies zulassen möchten. Bitte beachten Sie, dass bei einer Ablehnung womöglich nicht alle Funktionalitäten der Seite zur Verfügung stehen.
                </CookieConsent>
                <div id={this.props.id} className="templates-wrapper" ref={(d) => {  this.dom = d; }}>
                    <Helmet>
                        <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet"/>
                    </Helmet>
                    <Header id="Nav0_0" key="Nav0_0"/>
                    {this.props.children}
                    <Footer />
                </div>
            </>
        );
    }
}
export default Layout