export const isValidUrl = (string) => {
    try {
        new URL(string);
        return true;
    } catch (_) {
        return false;
    }
};

export const germanDateToEnglishDate = (date) => {
    return new Date(date.split('/').reverse().join('/'));
}

export const getPathname = (url, isExtern=false) => {
    if(isExtern) {
        return url;
    }
    if(isValidUrl(url)) {
        return new URL(url).pathname;
    }
    return url;
};

export const getState = (url) => {
    if(!url) {
        return;
    }

    if(!url.includes('?')) {
        return;
    }

    const queryPart = url.split('?');
    const queryparams = queryPart[1];
    const params = queryparams.split('&');
    let pair = null;
    let data = [];

    params.forEach(function(d) {
        pair = d.split('=');
        data[pair[0]] = pair[1];
    });

    return data;
}