import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Link} from "gatsby";
import './submenu.css';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from "@material-ui/core";
import {getPathname, getState} from "./utils";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {OutboundLink} from "gatsby-plugin-google-analytics"


const StyledMenu = withStyles({
    root: {

    }
})(props => (
    <div elevation={0}
        {...props}
    />
));

export default function Submenu({item, subItems}) {
    const [open, setOpen] = React.useState(false);

    const isMobile = useMediaQuery('(max-width:1024px)');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    }

    return (
        <div onMouseEnter={!isMobile && handleOpen} onMouseLeave={!isMobile && handleClose} style={{position: "relative"}}>
            <div onClick={handleToggle}>
                <ListItem button className="menu-link">
                    <ListItemText primary={item.title} />
                    { Boolean(open) ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            </div>
            {open &&  <div className='submenu'>
                {
                    subItems.map((childItem, index) => {
                        const isExtern = childItem.classes && childItem.classes.includes("extern");

                        if(isExtern) {
                            return <OutboundLink href={childItem.url} className="submenu-link" key={childItem.url + "_" + index} target='_blank'>
                                <MenuItem onClose={handleClose} className="submenu-link-menu-item">
                                    {childItem.title}
                                </MenuItem>
                            </OutboundLink>
                        }
                        return <Link to={getPathname(childItem.url)} state={getState(childItem.url)} className="submenu-link" key={childItem.url + "_" + index}>
                            <MenuItem onClose={handleClose} className="submenu-link-menu-item">
                                {childItem.title}
                            </MenuItem>
                        </Link>
                    })
                }
            </div>}
        </div>
    );
};